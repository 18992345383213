import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Schema } from "./schemas/schema";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "type-compatibility"
    }}>{`Type Compatibility`}</h1>
    <hr></hr>
    <p>{`The Hegel tries to implement type safety in JavaScript via strong type system. It means that analyzer ensure that variable value is always assignable to declarated (or inferenced) static type.`}</p>
    <h2 {...{
      "id": "which-type-is-assignable-to-another"
    }}>{`Which type is assignable to another?`}</h2>
    <p>{`Next hierarchy is presented in Hegel.`}</p>

    <Schema type="hierarchy" mdxType="Schema" />
    <p>{`This hierachy defines rules of subtyping: "Every type which is higher in the hierarchy will be a super type for current type". Lets discover that by examples.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Tuple Type
const tuple: [number, number] = [42, 24]; // 👌!

// This is also an Array
const array: Array<number> = [42, 24]; // 👌!

// This is also an Object
const object: Object = [42, 24]; // 👌!

// This is also "unknown"
const unknown: unknown = [42, 24]; // 👌!
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// But not in reverse order
// Object is not a Tuple
// Error: Type "Object" is incompatible with type "[number, number]"
const tuple: [number, number] = new Object();

// And object is not a Array
// Error: Type "Object" is incompatible with type "Array<number>"
const array: Array<number> = new Object();

// But object is Object
const object: Object = new Object();

// And object is unknown
const unknown: unknown = new Object();
`}</code></pre>
    <h2 {...{
      "id": "function-types-compatibility"
    }}>{`Function types Compatibility`}</h2>
    <p>{`Functions does not create any hierarhy. Compatibility of two functions defined by the rule:
you can assign function to another only if actual arguments types are wider then declared and return type is more specific then declared. This rule sounds like: function is `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Covariance_and_contravariance_(computer_science)"
      }}>{`contravariant`}</a>{` by arguments and `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Covariance_and_contravariance_(computer_science)"
      }}>{`covariant`}</a>{` by return.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`let func: (number) => number = () => 42;

func = (a: number): number => a; // 👌!

// It's okay because "number | string" is wider than "number"
func = (a: number | string): number => 44; // 👌!

// It's okay because "42" is more specific than "number"
func = (a: number): 42 => 42; // 👌!

// Error: Type "(42) => number" is incompatible with type "(number) => number"
// Because "42" is more specific than "number"
func = (a: 42): number => 42;

// Error: Type "(number) => number | string" is incompatible with type "(number) => number"
// Because "number | string" is wider than "number"
func = (a: number): number | string => 42;
`}</code></pre>
    <h2 {...{
      "id": "generics-compatibility"
    }}>{`Generics Compatibility`}</h2>
    <p>{`Two generic may be contained in hierarchy of each other only if their actual type parameter is in heierarchy of each other. For example, imagine generic container class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Container<T> {
  value: T;

  constructor(value) {
    this.value = value;
  }
}
`}</code></pre>
    <p>{`We can see a the same subtyping behavior as with primitive types:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const wrapped42: Container<42> = new Container<42>(42); // 👌!
const wrappedNumber: Container<number> = new Container<42>(42); // 👌!

// Error: Type "Container<number>" is incompatible with type "Container<42>"
const wrappedNumber42: Container<42> = new Container<number>(84);

// Error: Type "Container<number>" is incompatible with type "Container<'Hello, World'>"
const wrappedHelloWorld: Container<"Hello, World"> = new Container<number>(42);
`}</code></pre>
    <p>{`It's because `}<inlineCode parentName="p">{`Container`}</inlineCode>{` type parameters are contained in hierarchy of each other.`}</p>
    <p>{`But, as you can see, only literals were used for examples. It's because every subtype of Object in JavaScript act not like a value but like a "pointer" at value. So, for all reference types you can't do assign between variables if variables are not the same type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const wrapped42: Container<42> = new Container<42>(42); // 👌!

// Error: Type "Container<42>" is incompatible with type "Container<number>"
const wrappedNumber: Container<number> = wrapped42;
`}</code></pre>
    <h2 {...{
      "id": "soundness"
    }}>{`Soundness`}</h2>
    <p>{`Soundness is an ability of system to guarantee that after analysis your program will not be in invalid state.`}</p>
    <p>{`Hegel tries to implement the same soundness type system as `}<a parentName="p" {...{
        "href": "https://reasonml.github.io/"
      }}>{`ReasonML/OCaml`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.haskell.org/"
      }}>{`Haskell`}</a>{`, `}<a parentName="p" {...{
        "href": "https://elm-lang.org/"
      }}>{`Elm`}</a>{` and other ML
-based languages. It means Hegel tries to catch every single error that might happen at runtime without runtime type checking.`}</p>
    <blockquote>
      <p parentName="blockquote">{`If you familiar with `}<a parentName="p" {...{
          "href": "https://www.rust-lang.org/"
        }}>{`Rust Language`}</a>{` you may know that Rust doesn't provide any type information in runtime and all types will be stripped after type checking, but Rust program is still type safe. It's our dream to implement the same type checking in JavaScript.`}</p>
    </blockquote>
    <h2 {...{
      "id": "hegel-issue"
    }}>{`Hegel Issue`}</h2>
    <p>{`Also, Hegel as other tools has hack for ignoring any kind of errors. You can pass the "@hegel-issue" comment and error will be ignored.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// @hegel-issue
const a: string = 44;
`}</code></pre>
    <p>{`But, it's NOT RECOMMENDED to use it for a quick fix or business task solution. "@hegel-issue" should be used only if the error is Hegel bug.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      